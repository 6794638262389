@import "~antd/dist/antd.css";

* {
   font-family: "Mitr", sans-serif;
}

p {
   font-family: "Voces", cursive;
}

.main-content {
   font-family: "Voces", cursive;
}

.ant-layout-header {
   background: #e6f7ff;
   padding: 0 16px !important;
}

.__header_title {
   color: #40a9ff;
}

.__main_card_table {
   border-radius: 6px;
   background: #fff;
   box-shadow: -12px 12px 24px #f0f0f0;
}

.__bpjs-card .ant-card-body,
.__bank-card_not_important .ant-card-body,
.__bank-card .ant-card-body {
   padding: 0 !important;
}

.__bank-card {
   background: #4776e6;
   /* fallback for old browsers */
   background: -webkit-linear-gradient(to top, #8e54e9, #4776e6);
   /* Chrome 10-25, Safari 5.1-6 */
   background: linear-gradient(to bottom, #8e54e9, #4776e6);
   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.__bank-card_not_important {
   background: #c9d6ff;
   /* fallback for old browsers */
   background: -webkit-linear-gradient(to right, #e2e2e2, #c9d6ff);
   /* Chrome 10-25, Safari 5.1-6 */
   background: linear-gradient(to right, #e2e2e2, #c9d6ff);
   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.__bpjs-card {
   background: #11998e;
   /* fallback for old browsers */
   background: -webkit-linear-gradient(to right, #38ef7d, #11998e);
   /* Chrome 10-25, Safari 5.1-6 */
   background: linear-gradient(to right, #38ef7d, #11998e);
   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.__bpjs-card div h1,
.__bank-card_not_important div h1,
.__bank-card div h1 {
   color: #fff;
}

.__bank-card div h1 {
   color: #fff;
   font-family: monospace;
   font-weight: 500;
}

.__bank-card_not_important div h1 {
   color: #000;
   font-family: monospace;
   font-weight: 500;
}

.__bpjs-card:hover {
   transform: translateY(-4px);
   box-shadow: none;
}

.__bank-card:hover,
.__bank-card_not_important:hover {
   transform: translateY(-4px);
   box-shadow: none;
}

.__employee_avatar_big {
   display: block;
   max-width: 100%;
   max-height: 1025px;
   width: auto;
   height: auto;
   border-radius: 6px;
}

.custom-budget-table {
   border-collapse: collapse;
   width: 100%;
}

.custom-budget-table td,
.custom-budget-table th {
   border: 1px solid #f0f0f0;
   padding: 8px;
   text-align: center;
}

.custom-budget-table td {
   border: 1px solid #f0f0f0;
   padding: 8px;
   font-weight: 300;
   font-size: 13px;
}

.component-detail-name {
   text-align: left !important;
}

.component-detail-main {
   background: #e6f7ff;
   transition: 500ms;
}

.component-detail-main:hover {
   background: #bae7ff;
   transition: 500ms;
}

.component-detail-main td {
   font-weight: 500;
}

.custom-budget-table th {
   border: 1px solid #cdc9c9;
   background: #fafafa;
   font-weight: 500;
}

.budget-plan-table td {
   text-align: left;
}

.budget-plan-table-title {
   text-align: center !important;
}

.custom-edit-btn {
   border-color: #ffc53d;
   color: #ffc53d;
}

.componentactv-tr {
   background: #fafafa;
   transition: 500ms;
}

.componentactv-tr:hover {
   background: #f0f0f0;
   transition: 500ms;
}

.componentactv-tr-child {
   transition: 500ms;
}

.componentactv-tr-child:hover {
   transition: 500ms;
   background: #f0f0f0;
}

.dudi-history-step > .ant-steps-item-container > .ant-steps-item-content {
   width: 100% !important;
}

.dudi-history-step > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
   margin-left: 16px !important;
}

.dudi_asp_container {
   margin: 0 auto;
   max-width: 720px;
   width: 100%;
}

.discuss-input-wrapper {
   height: 250px;
   margin-bottom: 48px;
}

.ql-snow .ql-picker.ql-font {
   width: 150px !important;
}

.clause-container .ql-editor,
.clause-container .ql-editor p,
.clause-container .ql-editor ol,
.clause-container .ql-editor ul,
.clause-container .ql-editor pre,
.clause-container .ql-editor blockquote,
.clause-container .ql-editor h1,
.clause-container .ql-editor h2,
.clause-container .ql-editor h3,
.clause-container .ql-editor h4,
.clause-container .ql-editor h5,
.clause-container .ql-editor h6 {
   font-family: "Times New Roman", serif;
}

.clause-container .ql-editor p,
.clause-container .ql-editor ol,
.clause-container .ql-editor ul,
.clause-container .ql-editor pre,
.clause-container .ql-editor blockquote {
   font-size: 12px;
}

/* Set dropdown font-families */
.ql-font span[data-value="TimesNewRoman"]::before {
   font-family: "Times New Roman", serif;
   content: "Times New Roman" !important;
}

/* Set dropdown font-families */
.ql-font span[data-value="Arial"]::before {
   font-family: "ArialArialman", sans-serif;
   content: "Arial" !important;
}

/* Set content font-families */
.ql-font-Arial {
   font-family: "Arial" !important;
}
.ql-font-TimesNewRoman {
   font-family: "Times New Roman", serif !important;
}
/* We do not set Courier since it is the default font */
