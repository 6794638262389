.card-parent {
   border-radius: 0.5rem;
   box-shadow: -8px 20px 24px #40a9ff;
}

.card-body {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}