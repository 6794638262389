._edit-btn {
   background: #ffc53d;
   margin-top: 8px;
   border-radius: 6px;
   border-color: #ffc53d;
}

._edit-btn:hover {
   color: #141414;
   background: #ffd666;
   border-color: #ffd666;
}

._edit-btn-photo {
   background: #597ef7;
   margin-top: 8px;
   border-radius: 6px;
   border-color: #597ef7;
   color: white;
}

._edit-btn-photo:hover {
   color: #141414;
   background: #85a5ff;
   border-color: #85a5ff;
   color: white;

}

.personal-data-info {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 16px;
   font-size: 12px;
}

._employee-avatar {
   object-fit: cover;
   width: 250px;
   height: 250px;
   border-radius: 50%;
   overflow: hidden;
   border: 5px solid #bae7ff;
}